import React from "react";

//   FaGuitar,
//   FaSwift,
//   FaRocket,
//   FaClock,
//   FaClinicMedical,
//   FaSkiing,
//   FaChess,
//   FaApple,
//   FaHome,
//   FaPizzaSlice,
//   FaBriefcase,
//   FaFootballBall,
// } from 'react-icons/fa';
// // import { Link } from 'gatsby';
// import { IconContext } from 'react-icons';
// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';

import Layout from "../components/Layout/Layout";

const AboutUs = () => {
  return (
    <Layout>
      <div className="page">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-title">Contacts</h1>
          </div>
        </div>

        <div className="block--white">
          <div className="row page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <p className="bold-text">S&T MOLD SRL </p>
              <p className="section-description">8 Сalea Iesilor Str. </p>
              <p className="section-description">
                MD 2069, Moldova, Chisinau  
              </p>
              <p className="section-description">
                Z Tower Business Center, 6 floor
              </p>
              <p className="section-description">t: + 373 22 837 960  </p>
              <p className="section-description">snt@snt.md </p>
              <p className="bold-text">SERVICE CENTER</p>
              <p className="section-description">8 Сalea Iesilor Str. </p>
              <p className="section-description">
                MD 2069, Moldova, Chisinau  
              </p>
              <p className="section-description">
                Z Tower Business Center, 1 floor
              </p>
              <p className="section-description">t: +373 22 817 404  </p>
              <p className="section-description">service@snt.md</p>
            </div>
            <div className="tile-container">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="500"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=ZTower&t=&z=19&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />
                  <a href="https://embedgooglemap.net/maps/60"></a>
                  {/* <br> */}
                  {/* <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}
 </style> */}

                  {/* <style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}
 </style> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
